




















import useMisc from "@/use/misc";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      deafualt: () => ({}),
    },
    participants: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(_, { root }) {
    const { reportType } = useMisc({ root });
    return { reportType };
  },
});
